///
/// Slate by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* List */

    ol {
        list-style: decimal;
        margin: 0 0 _size(element-margin) 0;
        padding-left: 1.25em;

        li {
            padding-left: 0.25em;
        }
    }

    ul {
        list-style: disc;
        margin: 0 0 _size(element-margin) 0;
        padding-left: 1em;

        li {
            padding-left: 0.5em;
        }

        &.alt {
            list-style: none;
            padding-left: 0;

            li {
                border-top: solid 1px;
                padding: 0.5em 0;

                &:first-child {
                    border-top: 0;
                    padding-top: 0;
                }
            }
        }
    }

    dl {
        margin: 0 0 _size(element-margin) 0;

        dt {
            display: block;
            font-weight: _font(weight-bold);
            margin: 0 0 (_size(element-margin) * 0.5) 0;
        }

        dd {
            margin-left: _size(element-margin);
        }
    }

    @mixin color-list($p: null) {
        $highlight: _palette($p, highlight);

        ul {
            &.alt {
                li {
                    border-top-color: _palette($p, border);
                }
            }
        }
    }

    @include color-list;
    