.lightbox {

  .row > .column {
    padding: 0 8px;
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  

  
  /* The Modal (background) */
  .modal {
    display: none;
    position: fixed;
    z-index: _misc(z-index-base) + 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    //opacity: 0.8;
  }
  
  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: black; //#ff00ff; //#fefefe;
    margin: auto;
    padding: 0;
    width: 90%;
    max-height: 100%;
    max-width: 1200px;
  }
  
  /* The Close Button */
  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Hide the slides by default */
  .mySlides {
    display: none;

    img {
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto;
        position: static;
        display: block;
        opacity: 1;
    }

  }
  

  #thumbnail-container {
    //display: flex;
    background-color: #ff00ff;
    margin: 0 auto;
    height: 10vh;
    width: fit-content;
    max-height: 100%;
    max-width: 100%;
  } 

  .thumbnails {

    img {
        opacity: 0.6;
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto;
        opacity: 1;
    }

    /* Create four equal columns that floats next to each other */
    .column {
        float: left;
        width: 20vw;
        height: 100%;
        text-align: center;
    }
  }

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
    text-decoration: none;
    border: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  /* Caption text */
  .caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
  }
  
  .active,
  .demo:hover {
    opacity: 1;
  }
  
  img.hover-shadow {
    transition: 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}