///
/// Slate by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Type */

    body {
        background-color: _palette(bg);
        color: _palette(fg);
    }

    body, input, select, textarea {
        font-family: _font(family);
        font-size: 13pt;
        font-weight: _font(weight);
        line-height: 1.65em;

        @include breakpoint('<=xlarge') {
            font-size: 12pt;
        }
    }

    a {
        @include vendor('transition', 'border-bottom-color #{_duration(transition)} ease-in-out');
        border-bottom: dotted 1px;
        text-decoration: none;

        &:hover {
            border-bottom-color: transparent;
        }
    }

    strong, b {
        font-weight: _font(weight-bold);
    }

    em, i {
        font-style: italic;
    }

    p {
        margin: 0 0 _size(element-margin) 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: _font(family-heading);
        font-weight: _font(weight-heading-bold);
        line-height: 1em;
        margin: 0 0 (_size(element-margin) * 0.75) 0;
        padding-bottom: 1.25em;
        position: relative;
        text-transform: uppercase;

        &:after {
            border-bottom: solid 1px;
            bottom: 0;
            content: '';
            display: inline-block;
            left: 0;
            position: absolute;
            width: 3rem;
        }

        a {
            border-bottom: 0;
            color: inherit;
            text-decoration: none;
        }

        &.alt {
            margin: 0 0 (_size(element-margin) * 0.5) 0;
            padding-bottom: 0;

            &:after {
                display: none;
            }
        }
    }

    h2 {
        font-size: 1.25em;
        line-height: 1.25em;
    }

    h3 {
        font-size: 1em;
        line-height: 1.5em;
    }

    h4 {
        font-size: 1.1em;
        line-height: 1.5em;
    }

    h5 {
        font-size: 0.9em;
        line-height: 1.5em;
    }

    h6 {
        font-size: 0.7em;
        line-height: 1.5em;
    }

    @include breakpoint('<=small') {
        h2 {
            line-height: 1.5em;
        }
    }

    sub {
        font-size: 0.8em;
        position: relative;
        top: 0.5em;
    }

    sup {
        font-size: 0.8em;
        position: relative;
        top: -0.5em;
    }

    blockquote {
        border-left: solid 4px;
        font-style: italic;
        margin: 0 0 _size(element-margin) 0;
        padding: calc(_size(element-margin) / 4) 0 calc(_size(element-margin) / 4) _size(element-margin);
    }

    code {
        border: solid 1px;
        font-family: _font(family-fixed);
        font-size: 0.9em;
        margin: 0 0.25em;
        padding: 0.25em 0.65em;
    }

    pre {
        -webkit-overflow-scrolling: touch;
        font-family: _font(family-fixed);
        font-size: 0.9em;
        margin: 0 0 _size(element-margin) 0;

        code {
            display: block;
            line-height: 1.75em;
            padding: 1em 1.5em;
            overflow-x: auto;
        }
    }

    hr {
        border: 0;
        border-bottom: solid 1px;
        margin: _size(element-margin) 0;

        &.major {
            margin: (_size(element-margin) * 1.5) 0;
        }

        &.minor {
            margin: (_size(element-margin) * 1.0) 0;
        }
    }

    .align-left {
        text-align: left;
    }

    .align-center {
        text-align: center;
    }

    .align-right {
        text-align: right;
    }

    @mixin color-typography($p: null) {
        $highlight: _palette($p, highlight);

        @if $p != null {
            background-color: _palette($p, bg);
            color: _palette($p, fg);
        }

        input, select, textarea {
            color: _palette($p, fg-bold);
        }

        a {
            @if $p == $highlight {
                color: _palette($p, fg-bold);
            }
            @else {
                color: _palette(accent1, bg);
            }
        }

        strong, b {
            color: _palette($p, fg-bold);
        }

        h1, h2, h3, h4, h5, h6 {
            color: _palette($p, fg-bold);
        }

        blockquote {
            border-left-color: _palette($p, border);
        }

        code {
            background: _palette($p, border-bg);
            border-color: _palette($p, border);
        }

        hr {
            border-bottom-color: _palette($p, border);
        }
    }

    .formal {
        text-align: center;
        &:after {
            border: none;
        }
        h1 {
            font-size: 2em;	
            
            line-height: 2em;
        }

        img, iframe {
            max-width: 100%;
        }
    }
    // Apply this class to big blocks of text for improved presentation.
    .words {
        line-height: 1.5em;
        // More typography tweaking to try...
        //letter-spacing: -.05em;
        //word-spacing: .1em;			
        //text-align: justify;

        &.formal {
            text-align: justify;

            h2, h3, h4 {
                text-align: left;
            }
        }

        p, blockquote, ul, ol {
            margin: 0 0 0.75em 0;	
            //padding: 0 1em 0 1em;		
        }

        ul, ol {
            padding-inline-start: 2em;
        }

        li {
            margin-bottom: 0.5em;
        }

        h2, h3 {
            //margin: 0.5em 0 0.5em 0;
            margin-top: 2em;
            padding-bottom: 0.5em;
        }

        img {
            max-width: 80%;
            margin: 2em auto 2em auto;
            display: block;
            box-shadow: 5px 5px 5px grey;
            border-radius: 1em; 
        }
    }

    @include color-typography;
