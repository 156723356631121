@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import 'fontawesome-all.min.css';
//@import url('https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,400,600|Raleway:500,700,800');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

/*
	Slate by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

// Breakpoints.

	@include breakpoints((
		xlarge:   ( 1281px,  1680px ),
		large:    ( 981px,   1280px ),
		medium:   ( 737px,   980px  ),
		small:    ( 481px,   736px  ),
		xsmall:   ( null,    480px  )
	));

// Mixins.

	@mixin color($p) {
		@include color-typography($p);
		@include color-box($p);
		@include color-button($p);
		@include color-features($p);
		@include color-form($p);
		@include color-icon($p);
		@include color-list($p);
		@include color-menu($p);
		@include color-pagination($p);
		@include color-post($p);
		@include color-section($p);
		@include color-table($p);
	}

// Base.

	@import 'base/reset';
	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/row';
	@import 'components/box';
	@import 'components/button';
	@import 'components/features';
	@import 'components/form';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/actions';
	@import 'components/icons';
	@import 'components/bulleted-icons';
	@import 'components/menu';
	@import 'components/pagination';
	@import 'components/post';
	@import 'components/posts';
	@import 'components/section';
	@import 'components/spotlight';
	@import 'components/table';
	@import 'components/wrapper';
	@import 'components/lightbox';
	@import 'components/search';

// Layout.

	@import 'layout/header';
	@import 'layout/banner';
	@import 'layout/main';
	@import 'layout/footer';
	@import 'layout/navPanel';
