///
/// Slate by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Header */

	body {
		padding-top: 3.5em;
	}

	@include keyframes('reveal-header') {
		0% { top: -4em; opacity: 0; }
		100% { top: 0; opacity: 1; }
	}

	#header {
		background-color: _palette(bg);
		box-shadow: 0 0 0.125em 0 rgba(0,0,0,0.35);
		font-family: _font(family-heading);
		font-weight: _font(weight-heading);
		height: 3.5em;
		left: 0;
		line-height: 3.5em;
		position: fixed;
		text-transform: uppercase;
		top: 0;
		width: 100%;
		z-index: _misc(z-index-base);

		h1 {
			height: inherit;
			left: 1.5em;
			line-height: inherit;
			margin: 0;
			padding-bottom: 0;
			position: relative;

			&:after {
				display: none;
			}

			a {
				color: inherit;

				span {
					font-weight: _font(weight-heading);
				}
			}
		}

		nav {
			height: inherit;
			line-height: inherit;
			position: absolute;
			right: 1.5em;
			top: 0;

			.searchbox {
				padding-left: 0;
				margin-left: 0.5em;
			}

			> ul {
				list-style: none;
				margin: 0;
				padding: 0;

				> li {
					display: inline-block;
					margin-left: 1.5em;
					padding: 0;

					> ul {
						display: none;
					}

					a {
						@include icon(false, solid);
						border-bottom: 0;

						&:before {
							margin-right: 0.75em;
						}
					}

					&:first-child {
						margin-left: 0;
					}


				}
			}
		}

		.navPanelToggle {
			@include icon(false, solid);
			border: 0;
			height: 4em;
			position: absolute;
			right: 0;
			top: 0;
			width: 5em;
			display: none;

			&:before {
				content: '\f0c9';
				width: 3em;
				height: 3em;
				display: block;
				line-height: inherit;
				position: absolute;
				right: 0;
				top: 0;
				text-align: center;
			}
		}

		& + #banner {
			margin-top: -3.5em;
		}

		&.reveal {
			@include vendor('animation', 'reveal-header 0.5s ease');
		}

		&.alt {
			@include color-button(accent1);
			@include color-typography(accent1);
			@include vendor('animation', 'none');
			background-color: transparent;
			box-shadow: none;
			overflow: hidden;
			position: absolute;
			top: 1.5em;

			h1 {
				left: 2.5em;
			}

			nav {
				right: 2.5em;
			}
		}

		@include breakpoint('<=large') {
			&.alt {
				top: 0.75em;

				h1 {
					left: 1.5em;
				}

				nav {
					right: 1.5em;
				}
			}
		}

		@include breakpoint('<=medium') {
			&.alt {
				top: 0;
			}

			nav {
				display: none;
			}

			.navPanelToggle {
				display: block;
			}
		}

		@include breakpoint('<=small') {
			height: 3em;
			line-height: 3em;

			h1 {
				left: 1em !important;

				a {
					font-size: 0.9em;
				}
			}
		}
	}

	.dropotron {
		list-style: none;
		padding: 0;
		background: _palette(bg);
		color: _palette(fg-bold);
		min-width: 13em;
		padding: 0.75em;
		margin-top: -0.5em;
		font-family: _font(family-heading);
		font-weight: _font(weight-heading);
		text-transform: uppercase;
		box-shadow: 0 0 0.125em 0 rgba(0,0,0,0.35);

		li {
			box-shadow: inset 0 1px 0 0 _palette(border);

			&:first-child {
				box-shadow: none;
			}
		}

		a {
			color: inherit;
			text-decoration: none;
			letter-spacing: 0.05em;
			font-size: 0.8em;
			display: block;
			line-height: 3em;
			border: 0;
		}

		&.level-0 {
			margin-top: 0;

			&:before {
				content: '';
				border-left: solid 0.5em transparent;
				border-right: solid 0.5em transparent;
				border-bottom: solid 0.5em _palette(bg);
				display: block;
				position: absolute;
				top: -0.45em;
				left: 50%;
				margin-left: -0.25em;
			}
		}
	}


	.hide-mobile {
		@include breakpoint('<=xsmall') {
			visibility: hidden;
		}
	}