///
/// Slate by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Image */

    .image {
        border: 0;
        display: inline-block;
        position: relative;

        img {
            display: block;
        }

        &.left,
        &.right {
            max-width: 40%;

            img {
                width: 100%;
            }
        }

        &.left {
            float: left;
            margin: 0 1.5em 1em 0;
            top: 0.25em;
        }

        &.right {
            float: right;
            margin: 0 0 1em 1.5em;
            top: 0.25em;
        }

        &.center {
            margin: 0 auto;
            width: 100%;
        }

        &.fit {
            display: block;
            margin: 0 0 _size(element-margin) 0;
            width: 100%;
            text-align: center;

            img {
                width: 100%;
                display: inline;                
            }
        }

        &.round {
            img {
               border-radius: 1em; 
            }
        }

        &.shadow {
            img {
                box-shadow: 5px 5px 5px grey;
            }
        }

        &.main {
            display: block;
            margin: 0 0 (_size(element-margin) * 1.5) 0;
            width: 100%;

            img {
                width: 100%;
            }
        }

        &.small {
            max-width: 25%;					
            float: right;
            margin: 0 0em 1em 1.5em;
            top: 0.25em;

            img {
                width: 100%;
            }
        }
    }

    .image_block {
        margin: 0 auto;
        width: fit-content;
        text-align: center;
        img {
            max-height: 30vh;
            max-width: 22%;
            margin: 0.5em;
            border-radius: 1em;
            box-shadow: 5px 5px 5px grey;
            display: inline;
        }
    }

    img[src*="#inline-float-right-plain"] {
        max-width: 20%;
        float: right;
        margin: 0.5em 0 1em 1em;        
        box-shadow: none;
        border-radius: 0;
    }

    img[src*="#inline-float-right"] {
        max-width: 20%;
        float: right;
        margin: 0.5em 0 1em 1em;        
    }

    img[src*="#inline-float-left"] {
        max-width: 20%;
        float: left;
        margin: 0.5em 1em 1em 0;
    }

    img[src*="#plain-center"] {
        border-radius: 0;
        box-shadow: none;
        margin: 0 auto;
    }