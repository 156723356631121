///
/// Slate by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out');
		border: 0;
		border-radius: 0;
		cursor: pointer;
		display: inline-block;
		font-family: _font(family-heading);
		font-weight: _font(weight-heading-bold);
		height: 2.75em;
		line-height: 2.75em;
		padding: 0 1.5em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;

		&.icon {
			padding-left: 1.35em;

			&:before {
				margin-right: 0.5em;
			}
		}

		&.fit {
			display: block;
		}

		&.small {
			font-size: 0.8em;
		}

		&.large {
			font-size: 1.35em;
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}
	}

	@mixin color-button($p: null) {
		$highlight: _palette($p, highlight);

		input[type="submit"],
		input[type="reset"],
		input[type="button"],
		button,
		.button {
			background-color: transparent;
			color: _palette($p, fg-bold) !important;
			box-shadow: inset 0 0 0 1px _palette($p, fg-bold);

			&:hover {
				background-color: _palette($p, border-bg);
			}

			&:active {
				background-color: _palette($p, border2-bg);
			}

			&.primary {
				box-shadow: none;

				@if $p == $highlight {
					background-color: _palette($p, fg-bold);
					color: _palette($p, bg) !important;
				}
				@else {
					background-color: _palette($highlight, bg);
					color: _palette($highlight, fg-bold) !important;

					&:hover {
						background-color: lighten(_palette($highlight, bg), 5);
					}

					&:active {
						background-color: darken(_palette($highlight, bg), 5);
					}
				}
			}
		}
	}

	@include color-button;