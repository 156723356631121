///
/// Slate by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Features */

    .features {
        @include vendor('display', 'flex');
        @include vendor('justify-content', 'space-between');
        position: relative;

        a {
            border: none;
            text-decoration: none;
        }

        > section {
            width: calc(33.33333333% - 5em);
            @include vendor('display', 'flex');

            &:before {
                content: '';
                display: block;
                height: 100%;
                margin-left: -3.75em;
                position: absolute;
                top: 0;
                width: 1px;
            }

            &:first-child {
                border-left: 0;

                &:before {
                    display: none;
                }
            }
        }

        .feature-content {
            @include vendor('display', 'flex');
            @include vendor('flex-direction', 'column');            
            height: 100%;

            .footer-link {
                margin-top: auto;
                margin-left: auto;
                text-align: right;

                &:hover {
                    text-decoration: underline dotted;
                }
            }
        }

        @include breakpoint('<=large') {
            > section {
                width: calc(33.33333333% - 2.5em);

                &:before {
                    margin-left: -2em;
                }
            }
        }

        @include breakpoint('<=medium') {
            @include vendor('flex-direction', 'column');

            > section {
                width: 100%;
                @include vendor('flex-direction', 'column');
                
                &:before {
                    width: 100%;
                    height: 1px;
                    margin-left: 0;
                    position: relative;
                    top: -3em;
                }

                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        @include breakpoint('<=small') {
            > section {
                &:before {
                    top: -2.5em;
                }
            }
        }
    }

    @mixin color-features($p: null) {
        .features {
            > section {
                &:before {
                    background-color: _palette($p, border);
                }
            }
        }
    }

    @include color-features;
