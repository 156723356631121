///
/// Slate by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Post */

    .post {
        @include vendor('display', 'flex');
        @include vendor('flex-direction', 'column');
        @include vendor('align-items', 'stretch');
        margin-bottom: _size(element-margin);

        .image {
            display: block;
            margin-bottom: (_size(element-margin) * 0.5);
            width: 100%;
            -ms-flex: 0 0 auto;

            img {
                width: 100%;
                // This will crop images to the same size.
                max-height: 20vh;
                height: 20vh;
                object-fit: cover;
            }
        }

        .content {
            @include padding(2.5em, 2.5em);
            @include vendor('flex-grow', '4');

            @include vendor('display', 'flex');
            @include vendor('flex-direction', 'column');
            @include vendor('align-items', 'stretch');
            height: 100%;

            .actions {
                margin-top: auto;
                margin-left: auto;
                text-align: right;
            }
        }

        @include breakpoint('<=large') {
            .content {
                @include padding(2em, 2em);
            }
        }

        @include breakpoint('<=small') {
            display: block;

            .content {
                @include padding(2em, 1.5em);
            }
        }
    }

    .post-preview {
        .thumbnail {
            display: inline-block; 
            width: 30%; 
            vertical-align: top;

            img {
                object-fit: cover;
                width: 90%;
            }

            a {
                border-bottom: none;
            }
        }

        .excerpt {
            display: inline-block; 
            width: 70%; 
            vertical-align: top;            
        }

        @include breakpoint('<=small') {
            // stack 'em instead of side-by-side	
            .thumbnail {
                display: block; 
                width: 100%; 
                vertical-align: top;
                object-fit: cover;
                
                img {
                    width: 100%;
                }
            }
    
            .excerpt {
                display: block; 
                width: 100%; 
                vertical-align: top;            
            }
        }
    }

    .signature {
        //text-align: right;
    }


    @mixin color-post($p: null) {
         .post {
             .content {
                 @if _palette($p, box) == transparent {
                    background-color: transparent;
                    border: solid 1px _palette(border);
                 }
                 @else {
                    background-color: _palette(bg);
                     border: 0;
                 }
             }
         }
    }

    @include color-post;
