///
/// Slate by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Banner */

	#banner {
		@include color-typography(accent1);
		min-height: 40em;
		height: 75vh;
		position: relative;

		&:after {
			@include vendor('pointer-events', 'none');
			@include vendor('transition', 'opacity 1.5s ease-in-out, visibility 1.5s');
			background: #000511;
			content: '';
			display: block;
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			top: 0;
			visibility: hidden;
			width: 100%;
			z-index: 2;
		}

		.indicators {
			bottom: 1.5em;
			left: 0;
			list-style: none;
			margin: 0;
			padding: 0;
			position: absolute;
			text-align: center;
			width: 100%;
			z-index: 2;

			li {
				cursor: pointer;
				display: inline-block;
				height: 2em;
				overflow: hidden;
				padding: 0;
				position: relative;
				text-indent: -6em;
				width: 2em;

				&:before {
					background: rgba(255,255,255,0.35);
					border-radius: 100%;
					content: '';
					display: inline-block;
					height: 0.8em;
					left: 50%;
					margin: -0.4em 0 0 -0.4em;
					position: absolute;
					text-indent: 0;
					top: 50%;
					width: 0.8em;
				}

				&.visible {
					&:before {
						background: #fff;
					}
				}
			}
		}

		> article {
			@include vendor('transition', 'opacity 1.5s ease, visibility 1.5s');
			background-attachment: fixed;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			left: 0;
			opacity: 0;
			position: absolute;
			text-align: center;
			top: 0;
			visibility: hidden;
			width: 100%;
			z-index: 0;

			&:before {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: #000;
				opacity: 0.35;
			}

			.inner {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				z-index: 1;

				> :last-child {
					margin-bottom: 0;
				}
			}

			h2 {
				font-weight: _font(weight-heading-extrabold);
				font-size: 2.75em;
				padding-bottom: 0;
				margin: 0 0 (_size(element-margin) * 0.25) 0;

				&:after {
					display: none;
				}
			}

			img {
				display: none;
			}

			&.visible {
				opacity: 1;
				visibility: visible;
			}

			&.top {
				z-index: 1;
			}

			&.instant {
				@include vendor('transition', 'none !important');
			}
		}

		body.is-preload & {
			&:after {
				opacity: 1.0;
				visibility: visible;
			}
		}

		@include breakpoint('<=large') {
			min-height: 35em;
			height: 60vh;

			> article {
				h2 {
					font-size: 2.25em;
				}
			}
		}

		@include breakpoint('<=medium') {
			min-height: 50em;
			height: 75vh;

			> article {
				background-attachment: scroll;
			}
		}

		@include breakpoint('<=small') {
			min-height: 25em;

			> article {
				.inner {
					width: 90%;
				}

				h2 {
					font-size: 1.75em;
					line-height: 1.25em;
				}
			}
		}

		body.is-mobile & {
			> article {
				background-attachment: scroll;
			}
		}
	}