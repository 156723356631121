///
/// Slate by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Section/Article */

	section, article {
		&.special {
			text-align: center;
		}
	}

	header {
		&.major {
			margin: 0 0 (_size(element-margin) * 2) 0;

			> :last-child {
				margin-bottom: 0;
			}

			h2 {
				font-size: 2.25em;
				font-weight: _font(weight-heading-extrabold);
				margin: 0 0 (_size(element-margin) * 0.35) 0;
				padding-bottom: 0.75em;
			}

			@include breakpoint('<=large') {
				h2 {
					font-size: 1.25em;
				}
			}

			@include breakpoint('<=medium') {
			}
		}
	}

	@mixin color-section($p: null) {
		header {
		}
	}

	@include color-section;