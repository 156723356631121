

    .mobile-search {
        visibility:hidden;
    }

    // This has to match the breakpoint where the search in the nav-bar gets
    // hidden
    @include breakpoint('<=medium') {
        .mobile-search {
            visibility:visible;

            margin: 0 auto;

            form {
                @include vendor('display', 'flex');
                @include vendor('flex-direction', 'row');
                @include vendor('justify-content', 'space-between');
                @include vendor('align-items', 'stretch');
                @include vendor('column-gap', '1em');
            }

            input {
                display: inline;
                flex-grow: 1;
                
            }
        }
    }

    @include breakpoint('<=small') {
        .mobile-search {
            form {
                @include vendor('flex-direction', 'column');
                @include vendor('row-gap', '1em');
                @include vendor('align-items', 'center');
            }

            input[type="submit"] {
                max-width: fit-content;
            }
        }
    }

    .search {
        ul {
            list-style-type: none;			
        }
    }

    #search-results {
        padding: 0;

        li {
            padding-left: 1em;
            padding-right: 1em;
        }
    }

    .search-result {
        border: none;
        padding: 0;
        margin: 0;


        .sr_url {
            font-size: 80%;
            color: _palette(fg-light);
        }

        h3 {
            padding: 0;
            margin: 0;
            color: _palette(fg-bold)
        }
        
        :after {
            border: none;
        }

        .sr_excerpt {
            margin-bottom: 2em;
        }

        a {
            text-decoration: none;
            border: none;
            margin-bottom: 2em;
        }
    }
