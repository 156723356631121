///
/// Slate by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Bulleted Icons */

	ul.bulleted-icons {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		padding: 0;

		li {
			margin: _size(element-margin) 0 0 0;
			overflow: hidden;
			padding-left: 4.5em;
			position: relative;
			width: 50%;

			.icon-wrapper {
				font-size: 0.65em;
				left: 0;
				position: absolute;
				top: 0;

				.icon {
					&:before {
						font-size: 1rem;
					}
				}
			}

			h3 {
				font-size: 0.9em;
				margin-bottom: _size(element-margin) * 0.25;
				padding-bottom: 0;

				&:after {
					display: none;
				}
			}
		}

		@include breakpoint('<=large') {
			li {
				width: 100%;
				margin: (_size(element-margin) * 0.5) 0 0 0;
			}
		}
	}